import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { FiCircle, FiEdit, FiLink, FiTrash2 } from 'react-icons/fi'
import { Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { Counter, Dialog, ReactTable, SearchFilter } from 'components'
import { TableSkeleton } from 'components/Skeletons'
import { authenticate, company, login, cookies } from 'services'
import { UserContext, MyInformationsContext, StatisticsContext } from 'providers'
import { usePermission, useStatistics } from 'hooks'
import { colors } from 'theme/colors'
import { DialogType } from 'types'
import { routes } from 'router'
import { Top } from './styles'
import Layout from 'layouts'
import { CellButton } from 'components/ReactTable/styles'
import { useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'

const whichSortedType = (sortBy) => {
  if (sortBy.length === 0) {
    return null
  }
  // Since multiple sorting is disable sorted column will always be the first entry ([0])
  const sortedColumn = sortBy[0]

  return {
    sortDirection: sortedColumn.desc ? 'desc' : 'asc',
    accessor: sortedColumn.id,
  }
}

const getColumnsConfig = (t, push, setIdToRemove, setSelectedCompany) => [
  {
    Header: t('company.table.0'),
    accessor: 'name',
    sortable: true,
  },
  {
    Header: t('company.table.1'),
    accessor: 'customers',
    // for now backend does not handle customers sorting
    sortable: false,
  },
  {
    Header: t('company.table.2'),
    accessor: 'edit',
    type: 'button',
    icon: <FiEdit color={colors.mikadoYellow} />,
    action: (id) => push(`${routes.edit.path}/${id}`),
    Cell: ({ cell }: any) =>
      !cell.row?.original?.isDeleted && (
        <CellButton onClick={() => push(`${routes.edit.path}/${cell.row.original.id}`)}>
          {cell.column.icon || <FiCircle />}
        </CellButton>
      ),
  },
  {
    Header: t('company.table.3'),
    accessor: 'remove',
    type: 'button',
    icon: <FiTrash2 color={colors.marine} />,
    Cell: ({ cell }: any) =>
      !cell.row?.original?.isDeleted && (
        <CellButton onClick={() => setIdToRemove(cell.row.original.id)}>
          {cell.column.icon || <FiCircle />}
        </CellButton>
      ),
  },
  {
    Header: t('company.table.4'),
    accessor: 'connection',
    type: 'button',
    Cell: ({ cell }: any) =>
      !cell.row?.original?.isDeleted && (
        <CellButton onClick={() => setSelectedCompany(cell.row.original.id)}>
          <FiLink color={colors.marine} />
        </CellButton>
      ),
  },
]

const Company = (): ReactElement => {
  const [selectedCompany, setSelectedCompany] = useState<string>('')
  const [idToRemove, setIdToRemove] = useState<string>('')
  const [isProcessing, process] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState([])
  const [pageCount, setPageCount] = React.useState<number>(1)

  const { user, setUser } = useContext(UserContext)
  const { refetchMyInformations } = useContext(MyInformationsContext)
  const { permission } = usePermission()
  const { statistics, setStatistics } = useContext(StatisticsContext)
  const {} = useStatistics(setStatistics, statistics)

  const { t } = useTranslation()
  const { push } = useHistory()
  const { refetch } = useQuery('auth', () => login.switch({ company: selectedCompany }), {
    enabled: false,
  })
  const deleteCompany = useQuery('auth', () => company.remove({ id: idToRemove }), {
    enabled: false,
  })
  const tableColumns = useMemo(
    () => getColumnsConfig(t, push, setIdToRemove, setSelectedCompany),
    [],
  )

  const tableInstance = useTable(
    {
      columns: tableColumns,
      data: dataTable || [],
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualSortBy: true,
      disableSortRemove: true,
      manualPagination: true,
      pageCount,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )
  const {
    setGlobalFilter,
    state: { pageIndex, sortBy, globalFilter },
  } = tableInstance

  const {
    refetch: refetchCompanies,
    data,
    isSuccess,
    isLoading,
  } = useQuery(
    ['companies', { sortBy, searchword: globalFilter || '', pageIndex: pageIndex + 1 }],
    () =>
      permission.admin &&
      company.collection({
        page: pageIndex + 1,
        itemsPerPage: 20,
        sortBy: whichSortedType(sortBy).sortDirection,
        searchWord: globalFilter || '',
      }),
    {
      staleTime: 10,
    },
  )

  const connectAsCompany = () => {
    if (selectedCompany) {
      refetch().then(({ data }) => {
        if (!data.token) return false
        authenticate.jwt(data.token).roles
        setUser({
          ...user,
          userId: authenticate.jwt(data.token).company,
          subscription: authenticate.jwt(data.token).roles.slice(-1).pop(),
          temp: data.token,
        })
        cookies.set([
          { key: 'token', value: data.token },
          { key: 'selectedCompanyToken', value: data.token },
        ])
        refetchMyInformations()
        push(routes.dashboard.path)
      })
    }
  }

  useEffect(() => {
    if (selectedCompany) connectAsCompany()
  }, [selectedCompany])

  useEffect(() => {
    if (isSuccess && data?.['hydra:member']) {
      setDataTable(data['hydra:member'])
      setPageCount(Math.ceil(data['hydra:totalItems'] / 10))
    }
  }, [data])

  const dialog: DialogType = {
    title: t('company.dialog.title'),
    content: t('company.dialog.content'),
    mark: t('company.dialog.mark'),
    type: 'error',
    action: {
      dismiss: {
        onClick: () => setIdToRemove(''),
        text: t('company.dialog.action.dismiss'),
      },
      active: {
        onClick: () => {
          process(true)
          deleteCompany.refetch().then(() => {
            process(false)
            refetchCompanies().then(() => setIdToRemove(''))
          })
        },
        text: t('company.dialog.action.confirm'),
      },
    },
  }

  return permission.admin ? (
    <Layout>
      {idToRemove && <Dialog {...dialog} isLoading={isProcessing} />}
      <Top>
        <Counter
          create
          type={'company'}
          count={permission.admin ? statistics.companies : data?.['hydra:totalItems']}
        />
        <SearchFilter
          placeholder={t('company.search')}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Top>
      {isLoading ? <TableSkeleton /> : <ReactTable tableInstance={tableInstance} />}
    </Layout>
  ) : (
    <Redirect to={`${routes.edit.path}/${user.userId}`} />
  )
}

export default Company
